import React from 'react'
import {Button} from "react-bootstrap";
import {FaArrowRight} from "react-icons/fa";

export default ({children, ...props}) => {
    return (
        <Button {...props}>
            <span className={'d-flex justify-content-center align-items-center'}>
            {children} <FaArrowRight className={'ml-2'} />
            </span>
        </Button>
    )
}

