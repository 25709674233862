export const topicProps = {
}

export const switcherParams = {
    buttonProps: {
        variant: 'primary'
    }
}

export const summaryParams = {
    locationProps: {
    },
    dateProps: {
    },
    ticketButton: {
        text: 'Get your tickets',
        props: {
            className: 'btn-gradient'
        }
    }
}

export const galleryParams = {
    colProps: {
        md: 4
    }
}

export const navbarParams = {
    togglerProps: {
        variant: "link",
        size: "sm",
    },
    ticketButton: {
        text: 'Tickets',
        props: {
            variant: "outline-light",
        }
    }
}

export const lineupParams = {
    highlightedColProps: {
        xs: 6,
        className: "artist-highlighted act-col"
    },
    colProps: {
        xs: 4,
        className: "act-col"
    },
    emptyText: 'To be announced',
    artist: {
        cardProps: {
            className: 'artist mb-4',
            style: {
                borderColor: '#ffffff',
                borderWidth: 10,
                boxShadow: '15px 15px 0 0 #fe7600'
            }
        },
        emptyText: 'To be announced'
    },
    stage: {
        buttonProps: {
            variant: 'primary',
        }
    }
}

export const sectionParams = {
    buttonProps: {
        variant: 'primary',
        className: 'btn-gradient'
    }
}

export const ticketParams = {
    colProps: {
        xs: 12,
        sm: 3
    },
    button: {
        text: "Koop nu",
        props: {
            variant: "primary",
            className: 'btn-gradient'
        }
    }
}
