import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'

export default ({ title }) => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "header/logo@3x.png" }) {
          childImageSharp {
            fixed(height: 72, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  )
  return <Img fixed={data.file.childImageSharp.fixed} alt={title} />
}
