import React from 'react'
import SocialMenu from 'gatsby-theme-psg/src/components/Social'
import useSiteMetadata from 'gatsby-theme-psg/src/components/SiteMetadata'
import Logo from './Logo'
import 'gatsby-theme-psg/src/components/Layout/Footer/style.scss'
import {PrimaryMenu} from 'gatsby-theme-psg/src/components/Layout/Navbar'
import {Container} from "react-bootstrap";

const Index = () => {
    return (
        <footer
            className="footer d-flex flex-column justify-content-around"
        >
            <FooterContent />
        </footer>
    )
}

const FooterContent = () => {
    const { title } = useSiteMetadata()
    return (
        <Container fluid={'xl'}>
            <Logo title={title} />
            <ul
                className="nav main-menu justify-content-center my-3"
            >
                <PrimaryMenu />
            </ul>
            <ul
                className="nav social-menu justify-content-center my-3"
            >
                <SocialMenu />
            </ul>
            <p
                className="text-xs mb-0"
            >
                © Copyright {new Date().getFullYear()}, All Rights Reserved.{' '}
                <a
                    href="/algemenevoorwaarden.pdf"
                >
                    General Conditions of {title}
                </a>{' '}
                apply to this event. <a href="/privacy-statement.pdf">Privacy statement</a>
            </p>
        </Container>
    )
}

export default Index
