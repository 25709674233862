import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";


export default ({borderBottom, height='calc(70vh)', children}) => {
    const data = useStaticQuery(
        graphql`
            query {
                file(relativePath: { eq: "header/background-new@3x.jpg" }) {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        # Makes it trivial to update as your page's design changes.
                        fluid(maxWidth: 2048, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                left: file(relativePath: { eq: "header/hero-frame-left@3x.png" }) {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        # Makes it trivial to update as your page's design changes.
                        fluid(maxWidth: 2048, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                right: file(relativePath: { eq: "header/hero-frame-right@3x.png" }) {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        # Makes it trivial to update as your page's design changes.
                        fluid(maxWidth: 2048, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )
  return (
    <header
        className="position-relative"
        style={{ height: height }}
    >
        <Img
            fluid={data.file.childImageSharp.fluid}
            style={{
                height: height
            }}
            imgStyle={{ objectFit: 'cover' }}
        />
        <Img
            fluid={data.left.childImageSharp.fluid}
             className={'position-absolute'}
             style={{
                 top: 0,
                 width: '40%',
                 maxHeight: '100%',
             }}
             />
        <Img
            fluid={data.right.childImageSharp.fluid}
            className={'position-absolute'}
            style={{
                top: 0,
                minWidth: '40%',
                maxHeight: '100%',
                right: 0,
            }}
        />
        {borderBottom &&
        <Img
            fluid={borderBottom.childImageSharp.fluid}
            className={'position-absolute'}
            style={{
                width: '100%',
                bottom: 0,
            }}
        />
        }
        <div
            className={'position-absolute'}
            style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            {children}
        </div>
    </header>
  )
}