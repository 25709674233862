import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import {useLocation} from "@reach/router";

export default ({ title }) => {
    const home = useLocation().pathname === '/';
    const data = useStaticQuery(
        graphql`
            query {
                file(relativePath: { eq: "footer/logo-wide@3x.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 340) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                white: file(relativePath: { eq: "header/logo@3x.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 340) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )
    return (
        <Img
            fluid={home ? data.file.childImageSharp.fluid : data.white.childImageSharp.fluid}
            alt={title}
            className={'mx-auto'}
            style={{
                maxWidth: 340
            }}
        />
    )
}
